<!--
 * @Description: 无牌车查询订单 出场 noPlateNumberPay 有关订单页面
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-01-17 13:52:18
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="订单详情" />
    <van-row class="main">
      <van-row class="infoBox">
        <van-row class="infoTitle">
          <img :src="require('@/assets/commonPage/orderIcon.png')"
               alt="">
          <span class="wenzi">订单详情</span>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">订单号</van-col>
          <van-col class="details">{{orderList.orderSequence?orderList.orderSequence:''}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">车牌号</van-col>
          <van-col class="details">{{orderList.plateNumber?orderList.plateNumber:"无牌车"}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">在停地</van-col>
          <van-col class="details">{{orderList.parkName?orderList.parkName:""}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">开始时间</van-col>
          <van-col class="details">{{orderList.entranceTime?orderList.entranceTime:""}}</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">停车时长</van-col>
          <van-col class="details">{{orderList.parkDuration?orderList.parkDuration:"0"}}</van-col>
        </van-row>
        <van-row class="infoLine"
                 v-if="billRuleCode">
          <van-col class="name">计费规则</van-col>
          <van-col style="color:red">
            <span v-if="billRuleCode===1">半小时1元</span>
            <span v-if="billRuleCode===2">1小时2元</span>
          </van-col>
          <van-col style="color:blue;margin-left: 20px"
                   @click="queryBillingRuleDetail">详情</van-col>
        </van-row>
        <van-row class="infoLine">
          <van-col class="name">收款单位</van-col>
          <van-col class="details">中交四公局(重庆)城市建设发展有限公司</van-col>
        </van-row>
      </van-row>
      <van-row class="infoBox">
        <van-row class="infoLine"
                 style="margin-bottom:8px">
          <van-col class="name">应收费用</van-col>
          <van-col class="pay">
            <span style="color:#19A9FC;font-size:20px;">{{orderList.receivableMoneyAmount?orderList.receivableMoneyAmount:""}}</span>
            <span v-if="showFree===2"> - </span>
            <span style="color:red;font-size:20px"
                  v-if="showFree===2">{{orderList.receivableMoneyAmount?orderList.receivableMoneyAmount:""}}</span>元
          </van-col>
        </van-row>
      </van-row>
      <van-row>
        <van-button type="info"
                    class="payBtn"
                    @click="handleClickPay">支 付</van-button>
      </van-row>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
// import formatDuration from '@/utils/duration'
import parkStopTime from '@/utils/parkStopTime'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      openid: '',
      deviceId: '',
      toast: '卡口前暂无车辆',
      showFree: 1, // 计费规则是否免费 2是免费 1是收费
      orderList: {}, // 订单详情列表
      orderSequence: '', // 订单号
      billingRuleDefId: '', // 计费规则编号
      billRuleCode: 0,
      parkId: ''
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.queryOpenid()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () {

  }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取code
    getQueryString (name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = window.location.search.substring(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    // 获取openid
    queryOpenid () {
      // 第一次进
      if (!sessionStorage.getItem('openid')) {
        const info = {
          code: this.getQueryString('code'),
          type: 'officialAccount'
        }
        this.$noPlateNumberPay.getOpenIdByCode(info).then(res => {
          sessionStorage.setItem('openid', res.resultEntity.openid)
          this.queryOrderList()
        })
      } else {
        this.queryOrderList()
      }
    },
    // 获取订单详情列表
    queryOrderList () {
      const currentHref = window.location.href
      const deviceId = currentHref.split('deviceId=')[1]
      this.deviceId = currentHref.split('deviceId=')[1]
      const info = {
        openid: sessionStorage.getItem('openid'),
        deviceId: deviceId
      }
      this.$noPlateNumberPay.getOrderByOpenid(info).then(res => {
        if (!res.resultEntity) {
          this.$toast.fail('该车暂无无牌车订单')
          this.toast = '该车暂无无牌车订单'
          window.location.href = 'https://officialaccount.cqybxm.com/#/?page=parkPayForOthers'
        } else {
          this.orderList = res.resultEntity
          // 应收金额等于=应收-实收
          this.orderList.receivableMoneyAmount =
            Number((this.orderList.receivableMoneyAmount - this.orderList.receivedMoneyAmount) / 100).toFixed(2)
          // this.orderList.parkDuration = formatDuration(this.orderList.parkDuration)
          // 如果无离场时间 需要自己算
          if (!this.orderList.leaveTime) {
            this.orderList.parkDuration = parkStopTime(this.orderList.entranceTime, this.$fmtDate(new Date(), 'yyyy-MM-dd hh:mm:ss'))
          } else {
            this.orderList.parkDuration = parkStopTime(this.orderList.entranceTime, this.orderList.leaveTime)
          }
          this.orderSequence = res.resultEntity.orderSequence
          this.billingRuleDefId = res.resultEntity.billingRuleDefId
          if (this.billingRuleDefId === 'V7') {
            this.billRuleCode = 1
          } else if (this.billingRuleDefId === '1') {
            this.billRuleCode = 2
          }
          this.parkId = res.resultEntity.parkId
          this.queryForFree()
        }
      })
    },
    // 查询该计费规则是否收费
    queryForFree () {
      const info = {
        billingRuleDefId: this.billingRuleDefId
      }
      this.$payForParking.queryBillingRuleById(info).then(res => {
        // 2是免费 1是收费
        if (res.resultEntity.chargeStatusCode === 2) {
          this.showFree = 2
        } else if (res.resultEntity.chargeStatusCode === 1) {
          this.showFree = 1
        }
      })
    },
    // 查看计费规则详情
    queryBillingRuleDetail () {
      this.$router.push({
        name: 'billRuleDetail',
        query: { billRuleCode: this.billRuleCode }
      })
      // this.$router.push({
      //   name: 'billRuleDetail',
      //   query: { parkId: this.parkId }
      // })
      // this.$router.push({
      //   name: 'billRuleDetail',
      //   query: { billingRuleDefId: this.billingRuleDefId }
      // })
    },
    // 支付按钮
    handleClickPay () {
      // this.orderSequence = '2023030617394850338385'
      // this.showFree = 1
      // this.deviceId = '09G5103100100227'
      const info = {
        orderSequence: this.orderSequence
      }
      this.$commonPage.chargeBill(info).then(res => {
        if (this.showFree === 1 && Number(res.resultEntity.receivableMoneyAmount) - Number(res.resultEntity.receivedMoneyAmount) !== 0) {
          this.$router.push({
            name: 'choosePayNoPlateNumber',
            query: {
              deviceId: this.deviceId,
              payMoney: Number(res.resultEntity.receivableMoneyAmount) - Number(res.resultEntity.receivedMoneyAmount),
              payOrder: this.orderSequence
            }
          })
        } else if (this.showFree === 2 || (this.showFree === 1 && Number(res.resultEntity.receivableMoneyAmount) - Number(res.resultEntity.receivedMoneyAmount) === 0)) {
          const self = this
          self.$noPlateNumberPay.completeOrderFreeV2NotUserToken({ orderSequence: self.orderSequence }).then(() => {
            const Info = {
              deviceId: self.deviceId,
              openid: sessionStorage.getItem('openid'),
              orderSequence: self.orderSequence
            }
            self.$noPlateNumberPay.finishOrder4NoPlate(Info).then(() => {
              self.$toast.success('本单免费')
              window.location.href = 'https://officialaccount.cqybxm.com/#/?page=parkPayForOthers'
            })
          })
        }
      })

      // 不收费直接返回，订单结束
      // if (this.showFree === 2) {
      //   const info = {
      //     orderSequence: this.orderSequence
      //   }
      //   this.$payForParking.completeOrderFreeV2(info).then(() => {
      //     const self = this
      //     const Info = {
      //       deviceId: self.deviceId,
      //       openid: sessionStorage.getItem('openid'),
      //       orderSequence: self.orderSequence
      //     }
      //     self.$noPlateNumberPay.finishOrder4NoPlate(Info).then(Response => {
      //       self.$toast.success('本单免费')
      //       window.location.href = 'https://officialaccount.cqybxm.com/#/?page=parkPayForOthers'
      //     })
      //   })
      // } else if (this.showFree === 1) {
      //   if (this.orderList.receivableMoneyAmount && this.orderSequence) {
      //     this.$router.push({
      //       name: 'choosePayNoPlateNumber',
      //       query: {
      //         deviceId: this.deviceId,
      //         payMoney: this.orderList.receivableMoneyAmount,
      //         payOrder: this.orderSequence
      //       }
      //     })
      //   }
      // }
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .mainbody {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .mainitem {
      margin-top: 20px;
    }
  }
  .main {
    height: 100px;
    .infoBox {
      margin: 20px 3.2%;
      background-color: #ffffff;
      width: 93.6%;
      // height: 100px;
      border-radius: 5px;
      padding-top: 12px;
      .infoTitle {
        margin: 7.9px 0 12px 12px;
        font-size: 15px;
        color: #333;
        font-weight: 500;
        font-family: PingFang-SC;
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
        }
        .wenzi {
          margin-left: 10px;
        }
      }
      .line {
        border: 1px dashed #cfcfcf;
        width: 92.8%;
        margin-left: 12px;
      }
      .infoLine {
        height: 33.5px;
        line-height: 33.5px;
        .name {
          display: block;
          width: 80px;
          line-height: 33.5px;
          font-size: 15px;
          color: #909090;
          font-weight: 400;
          margin-left: 12px;
        }
        .details {
          display: block;
          width: calc(100% - 80px - 12px);
          min-height: 33.5px;
          line-height: 33.5px;
          font-size: 15px;
          color: #333333;
        }
        .pay {
          display: block;
          width: 200px;
          line-height: 33.5px;
          font-size: 15px;
          color: #333333;
        }
      }
    }
    .payBtn {
      width: 93.6%;
      margin: 0 3.2%;
      border-radius: 5px;
      background: #19a9fc;
      font-size: 17px;
    }
  }
}
</style>
